@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.home-page {
  padding-top: 50px;  
}

.form {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 260px;
  margin: 0 20px 100px 20px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #3498DB;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:hover, .form button:active, .form button:focus {
  background: #3498DB;
}

.form .message {
  margin: 15px 0 0;
  color: #B3B3B3;
  font-size: 12px;
}

.form .message a {
  color: #3498DB;
  text-decoration: none;
}

body {
  background: #FAFAFA; /* fallback for old browsers */
  /*background: rgb(52,152,219);*/
  /*background: linear-gradient(90deg, rgba(141,194,111,1) 0%, rgba(118,184,82,1) 50%);*/
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}

table {
  width: 80%;
  margin: 20px auto;
  table-layout: auto;
  background-color: #FFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.fixed {
  table-layout: fixed;
}

table,
tr,
th {
  border-collapse: collapse;
  margin: 20px, auto;
}

th,
tr {
  padding: 20px;
  border: 1px solid #E1E1E1;
  text-align: left;
}

td {
  padding: 20px;
  border: 1px solid #E1E1E1;
  text-align: left;
}

input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 150px;
  height: 50px;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 10px;
}

.btn-danger {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #dc3545;
  border-color: #dc3545;
  margin: .25rem .125rem;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 14px;
  font-size: 14px;
  border-radius: .25rem;
}

.btn-publish {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #3498DB;
  border-color: #3498DB;
  margin: .25rem .125rem;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 14px;
  font-size: 14px;
  border-radius: .25rem;
}

.inline {
  display: inline-block;
}